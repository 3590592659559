import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as colors from 'theme/colors';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  width: 100%;
  height: ${props => props.isOpen ? '450px' : '0px'};
	border-radius: 0px 0px 20px 20px;
  background-color: white;
  z-index: 10000;
`;

export const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
`;

export const LogoImage = styled.img`
  width: 47%;
  height: auto;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
  position: absolute;
	top: 20px;
	right: 20px;
`

export const LinksContainer = styled.div`
  display: flex;
	flex-direction: column;
	width: 100%;
  height: ${props => props.isOpen ? '450px' : '0px'};
  justify-content: center;
  align-items: center;
	overflow: hidden;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
	margin-bottom: 20px;
`;

