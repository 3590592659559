import React, { useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import romaImage from 'assets/roma.jpg';
import romaMobileImage from 'assets/mobile/roma-mobile.png';
import markerIcon from 'assets/mobile/coolicon.png';
import { MapCategoryButton, BlackButton } from 'components/Buttons';
import { AppPath } from 'App';
import ScrollToTop from 'components/ScrollToTop';
import { useScrollDispatch, setScrollToCategories } from 'components/useScroll';
import {
  HeadingH5,
  Body1,
  Subtitle1Bold,
  Subtitle1,
} from 'components/Typography';
import SocialLinks from 'components/SocialLinks';
import VideosSlider from 'components/VideosSlider';
import Header from 'components/Header';
import HeaderLight from 'components/HeaderLight';
import Spinner from 'components/Spinner';
import MobileHeader from 'components/Mobile/Header';
import Collage from 'components/Collage';
import useTranslation from 'hooks/useTranslation';
import useOnScreen from 'hooks/useOnScreen';
import { mainPage } from 'strings/mainPage';
import CategoriesSlider from './CategoriesSlider';
import LeafletMap from './LeafletMap';
import { useCategoriesMap } from './categoriesMap';
import MiddleSlider from './MiddleSlider';
import CategoriesSliderMobile from './CategoriesSliderMobile';
import MobileGallery from './MobileGallery';

import {
  MainPageContainer,
  ImageContainer,
  StyledImage,
  HeroTextContent,
  HeroBottomMenu,
  SocialButtonsContainer,
  CategoriesSliderContainer,
  MapContainer,
  MapCategoriesContainer,
  StyledMap,
  MapCategoryItem,
  StyledLocationIcon,
  AboutContainer,
  EcoCenterContainer,
  EcoCenterText,
  EcoCenterTextContainer,
  StyledLink,
  MobileMapButton,
  MobileIcon,
  MobileMapMenuContainer,
  StyledCloseIcon,
} from './style';

function MainPage({ topContainerRef, isScrolling }) {
  const categoriesMap = useCategoriesMap();
  const scrollDispatch = useScrollDispatch();

  const [showLoader, setShowLoader] = useState(true);

  const [currentCategory, setCurrentCategory] = useState(null);

  useEffect(() => {
    const defaultMapCategory = categoriesMap.find((cat) => cat.id === 9);
    setCurrentCategory(defaultMapCategory);
  }, [categoriesMap]);

  const [showMobileMapMenu, setShowMobileMapMenu] = useState(false);

  const t = useTranslation();
  const strings = t(mainPage);

  const history = useHistory();

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const ecoCenterRef = useRef(null);
  const visibleEcoCenter = useOnScreen(ecoCenterRef.current);

  const categoriesRef = useRef(null);
  const mainImageRef = useRef(null);

  useEffect(() => {
    mainImageRef.current.onload = () => {
      setShowLoader(false);
    };
  }, [mainImageRef]);

  const renderMapButtons = () =>
    categoriesMap.map((category) => (
      <MapCategoryItem key={category.id}>
        <MapCategoryButton
          selected={category.id === currentCategory?.id}
          onClick={() => setCurrentCategory(category)}
          $color={category.color}
        >
          <StyledLocationIcon
            $color={
              category.id === currentCategory?.id ? 'white' : category.color
            }
          />
        </MapCategoryButton>
        <Body1>{category.name}</Body1>
      </MapCategoryItem>
    ));

  return (
    <>
      <Helmet>
        <title>
          Стіна, це загадкове село на півдні Вінниччини, яке запрошує всіх до
          захопллючих відкриттів
        </title>
        <meta
          name="description"
          content="Стіна, це село, що розташоване на звивистих берегах річки Русава у південному Поділлі у Томашпільській територіальній громаді. Тут розивається зелений туризм, відбуваються заходи неформальної освіти, культурні і мистецькі події. Все це завдячуючи фунціонуванню Еко-Центру Стіна, котрий працює для молоді, активістів та гостей Стіни"
        />
      </Helmet>
      <ScrollToTop />
      {showLoader && <Spinner />}
      {largerSm ? (
        <Header topContainerRef={topContainerRef} isScrolling={isScrolling} />
      ) : (
        <MobileHeader />
      )}
      <MainPageContainer>
        <ImageContainer>
          <StyledImage
            src={largerSm ? romaImage : romaMobileImage}
            ref={mainImageRef}
          />
          <HeroTextContent>
            <Box textAlign={largerSm ? 'start' : 'center'}>
              <Body1>
                <i>{strings.heroText}</i>
              </Body1>
            </Box>
            <HeroBottomMenu>
              <SocialButtonsContainer>
                <SocialLinks />
              </SocialButtonsContainer>
            </HeroBottomMenu>
          </HeroTextContent>
        </ImageContainer>
        <AboutContainer>
          <HeadingH5>{strings.about}</HeadingH5>
          <Box marginTop="50px">
            <BlackButton
              onClick={() => {
                setScrollToCategories(scrollDispatch, false);
                history.push(`${AppPath.CATEGORY}/food-gastronomy`);
              }}
            >
              <HeadingH5>{strings.navLink3}</HeadingH5>
            </BlackButton>
          </Box>
        </AboutContainer>
        <CategoriesSliderContainer ref={categoriesRef}>
          {largerSm ? <CategoriesSlider /> : <CategoriesSliderMobile />}
        </CategoriesSliderContainer>
        <MiddleSlider />
        <MapContainer>
          <StyledMap>
            <LeafletMap currentCategory={currentCategory} />
          </StyledMap>
          {largerSm ? (
            <MapCategoriesContainer>
              {renderMapButtons()}
            </MapCategoriesContainer>
          ) : (
            <>
              {showMobileMapMenu ? (
                <MobileMapMenuContainer>
                  <StyledCloseIcon
                    onClick={() => setShowMobileMapMenu(false)}
                  />
                  {renderMapButtons()}
                </MobileMapMenuContainer>
              ) : (
                <MobileMapButton onClick={() => setShowMobileMapMenu(true)}>
                  <MobileIcon src={markerIcon} />
                </MobileMapButton>
              )}
            </>
          )}
        </MapContainer>
        {largerSm ? (
          <EcoCenterContainer ref={ecoCenterRef}>
            <Collage />
            <EcoCenterText visible={visibleEcoCenter}>
              <EcoCenterTextContainer>
                <Subtitle1Bold>{strings.mission}</Subtitle1Bold>
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="flex-end"
                  paddingRight="50px"
                  paddingTop="10px"
                  boxSizing="border-box"
                >
                  <Subtitle1>
                    <StyledLink to="/eco-center">{strings.readMore}</StyledLink>
                  </Subtitle1>
                </Box>
              </EcoCenterTextContainer>
            </EcoCenterText>
          </EcoCenterContainer>
        ) : (
          <MobileGallery />
        )}
        <VideosSlider />
      </MainPageContainer>
    </>
  );
}

export default MainPage;
