import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function ScrollToTop({ scrollTop = true }) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (!scrollTop) {
      return;
    }

    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
