import React from 'react';
import { useHistory } from 'react-router-dom';
import logoUk from 'assets/logo.png';
import logoEng from 'assets/logo_eng.png';
import useTranslation from 'hooks/useTranslation';
import { mainPage } from 'strings/mainPage';
import { HeadingLinks } from 'components/Typography';
import useAuth from 'components/useAuth';
import AdminButton from 'pages/Admin/AdminButton';
import Language from 'components/Language';
import { AppPath } from 'App';

import {
  HeaderContainer,
  LogoContainer,
  LogoImage,
  LinksContainer,
  StyledLink,
} from './style';

const logo = {
  uk: logoUk,
  en: logoEng,
};

function Header({ isScrolling }) {
  const t = useTranslation();
  const strings = t(mainPage);

  const history = useHistory();

  const { currentUser } = useAuth();

  return (
    <HeaderContainer isScrolling={isScrolling} width={window.screen.width}>
      <LogoContainer
        isScrolling={isScrolling}
        onClick={() => history.push(AppPath.ROOT)}
      >
        <LogoImage src={t(logo)} isScrolling={isScrolling} />
      </LogoContainer>
      <LinksContainer>
        <StyledLink to={AppPath.ECO_CENTER}>
          <HeadingLinks>{strings.navLink1}</HeadingLinks>
        </StyledLink>
        <StyledLink to={AppPath.GETTING_TO_STINA}>
          <HeadingLinks>{strings.navLink2}</HeadingLinks>
        </StyledLink>
        <Language />
        {currentUser && <AdminButton />}
      </LinksContainer>
    </HeaderContainer>
  );
}

export default Header;
