export const SUCCESS_ARTICLE = 'success_article';
export const SUCCESS_DRAFT = 'success_draft';
export const ERROR_ARTICLE = 'error-_rticle';
export const ERROR_DRAFT = 'error_draft';
export const ADD_ARTICLE = 'add_article';
export const ADD_DRAFT = 'add_draft';
export const EDIT_ARTICLE = 'edit_article';
export const EDIT_DRAFT = 'edit_draft';
export const DELETE_ARTICLE = 'delete_article';
export const DELETE_DRAFT = 'delete_draft';
export const REMOVE_MESSAGE = 'remove_message';
