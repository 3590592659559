import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import useVideos, {
  getVideos,
  addVideo,
  deleteVideo,
  editVideo,
} from 'components/useVideos';
import { RoundIconButton } from 'components/Buttons';
import { getVideoPreviewImg } from 'helpers/videoHelpers';
import { VideoImage } from 'components/VideosSlider/style';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import AddVideoForm from './Form';
import SequenceForm from './SequenceForm';
import {
  VideosContainer,
  VideoContainer,
  VideoGrid,
  DeleteButtonContainer,
} from './style';

function Videos() {
  const [{ videos }, dispatch] = useVideos();
  const [curPage, setPage] = useState(1);
  const pageSize = 10;
  const lastPage = Math.floor(videos.length / pageSize) + 1;

  const handleAddVideo = async (body) => {
    await addVideo(dispatch, body);
  };

  const handleDelete = (id) => {
    deleteVideo(dispatch, id);
  };

  const handleChangeVideo = async (video) => {
    await editVideo(dispatch, video, video.id);
    await getVideos(dispatch);
  };

  const onPageChange = (_, page) => setPage(page);

  return (
    <VideosContainer>
      <AddVideoForm addVideo={handleAddVideo} />
      <Box
        width="100%"
        marginBottom="20px"
        display="flex"
        justifyContent="center"
      >
        <Pagination
          count={lastPage}
          page={curPage}
          onChange={onPageChange}
          variant="outlined"
          color="primary"
        />
      </Box>
      <VideoGrid>
        {videos
          .sort((a, b) => Number(b.visible) - Number(a.visible))
          .slice((curPage - 1) * pageSize, curPage * pageSize)
          .map((video) => (
            <VideoContainer key={video.id}>
              <VideoImage src={getVideoPreviewImg(video.url)} />
              <DeleteButtonContainer>
                <Tooltip title="Delete video" placement="top">
                  <RoundIconButton
                    type="button"
                    onClick={() => handleDelete(video.id)}
                  >
                    <DeleteIcon />
                  </RoundIconButton>
                </Tooltip>
                <Tooltip title="Change visibility" placement="top">
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={
                          <FiberManualRecordIcon
                            fontSize="large"
                            style={{ fill: 'white' }}
                          />
                        }
                        checkedIcon={<CheckCircleIcon fontSize="large" />}
                        checked={video.visible}
                        onChange={() =>
                          handleChangeVideo({
                            ...video,
                            visible: !video.visible,
                            sequence: '0',
                          })
                        }
                      />
                    }
                  />
                </Tooltip>
              </DeleteButtonContainer>
              {video.visible && (
                <SequenceForm video={video} onVideoChange={handleChangeVideo} />
              )}
            </VideoContainer>
          ))}
      </VideoGrid>
    </VideosContainer>
  );
}

export default Videos;
