export function populateCategories(categories, articles = []) {
  const copyCategories = [...categories];

  articles.forEach((article) => {
    if (Array.isArray(article.categories)) {
      article.categories.forEach((categoryId) => {
        const category = copyCategories.find((cat) => cat.id === categoryId);
        if (category) {
          category.articles.push(article);
        }
      });
    }
  });
  return copyCategories;
}

export function populateCategoriesObjectForm(categories, articles = []) {
  return populateCategories(categories, articles).reduce((prev, current) => {
    prev[current.id] = current;
    return prev;
  }, {});
}

export function selectArticlesForCategory(categoryId, articles = []) {
  return articles.filter((article) =>
    article.categories.includes(Number(categoryId)),
  );
}
