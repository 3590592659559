import styled from 'styled-components';

export const SocialImage = styled.img`
  ${({ theme }) => `
    height: 40px;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      height: 50px;
    }

    ${theme.breakpoints.down("sm")} {
      height: 30px;

      &:hover {
        height: 30px;
      }
    }
  `}
`;

export const SocialImageYtube = styled.img`
  ${({ theme }) => `
    height: 30px;
    width: auto;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      height: 40px;
    }

    ${theme.breakpoints.down("sm")} {
      height: 25px;

      &:hover {
        height: 25px;
      }
    }
  `}
`;
