import { HeadingH4 } from 'components/Typography';
import styled from 'styled-components';

export const MainContainer = styled.div``;

export const StyledImage = styled.img`
  ${({ theme, width }) => `
  width: ${width ? width : 80}%;
  height: auto;

  ${theme.breakpoints.down('sm')} {
    width: 100%;
    }
  `}
`;

export const MediaContainer = styled.div`
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.rowWidth};
`;

export const FullWidthHeading = styled(HeadingH4)`
  width: 100%;
`;
