import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HeadingH5, Body1 } from 'components/Typography';
import { createSlug } from 'helpers/createSlug';
import { getArticleHeaderText } from 'helpers/textEditorHelper';
import useTranslation from 'hooks/useTranslation';
import { AppPath } from 'App';
import Box from '@material-ui/core/Box';
import Pagination from '@material-ui/lab/Pagination';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  StyledSwiperSlideInnerContainer,
  BottomContainer,
  Image,
} from './style';

function ArticlesSlider({ articles, currentCategoryTextId }) {
  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [curPage, setPage] = useState(1);
  const pageSize = largerSm ? 12 : 6;
  const lastPage = Math.floor(articles.length / pageSize) + 1;

  const t = useTranslation();
  const history = useHistory();

  const onPageChange = (_, page) => setPage(page);

  return (
    <>
      {articles.length > pageSize && (
        <Box
          width="100%"
          marginBottom="20px"
          marginTop="40px"
          display="flex"
          justifyContent="center"
        >
          <Pagination
            count={lastPage}
            page={curPage}
            onChange={onPageChange}
            variant="outlined"
            color="primary"
          />
        </Box>
      )}
      <BottomContainer>
        {articles
          .slice((curPage - 1) * pageSize, curPage * pageSize)
          .map((article) => (
            <Box
              key={article.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              marginTop="30px"
            >
              <StyledSwiperSlideInnerContainer
                onClick={() =>
                  history.push(
                    `${AppPath.CATEGORY}/${currentCategoryTextId}/articles/${article.slug}`,
                  )
                }
              >
                <Image src={article.thumb} />
              </StyledSwiperSlideInnerContainer>
              <Box width="70%" textAlign="center" paddingTop="10px">
                {largerSm ? (
                  <HeadingH5>
                    {getArticleHeaderText(t(article.header))}
                  </HeadingH5>
                ) : (
                  <Body1>{getArticleHeaderText(t(article.header))}</Body1>
                )}
              </Box>
            </Box>
          ))}
      </BottomContainer>
    </>
  );
}

export default ArticlesSlider;
