export const mainPage = {
  uk: {
    copyright: 'Всі права захищено',
    heroText:
      "В далекому минулому, ще в кам'яному віці, трипільської культури поселення було, тепер стоять кургани і сон-трава тут квітне, а селище, що поруч, Стіною нареклось.",
    embrText:
      'Стіна моя старенька, моя ти люба ненько, то ж будь завжди веселою і всіх гостей встрічай. Де б в світі ми не були та тягне нас серденько, бо хочеться зустрітися з тобою рідний край',
    navLink1: 'ЕКО-ЦЕНТР',
    navLink2: 'ЯК ДОЇХАТИ',
    navLink3: 'ВІДКРИЙ СТІНУ',
    mapCategory1: 'Харчування',
    mapCategory2: 'Проживання',
    mapCategory3: 'Магазини',
    mapCategory4: 'Цікавинки',
    mapCategory5: 'Майстер-класи',
    mapCategory6: 'Активний відпочинок',
    mapCategory7: 'Установи',
    mapCategory8: 'Місцевий продукт',
    mapCategory9: 'Еко-Центр',
    mapCategory10: 'Джерело',
    category1: 'Харчування, Гастрономія',
    category1First: 'Харчування',
    category1Second: 'Гастрономія',
    category2: 'Розміщення, Житло',
    category2First: 'Розміщення',
    category2Second: 'Житло',
    category3: 'Місцевий продукт',
    category3First: 'Місцевий',
    category3Second: 'продукт',
    category4: 'Волонтерство, Навчання',
    category4First: 'Волонтерство',
    category4Second: 'Навчання',
    category5: 'Активний відпочинок',
    category5First: 'Активний',
    category5Second: 'відпочинок',
    category6: 'Майстер-класи, Ремесла',
    category6First: 'Майстер-класи',
    category6Second: 'Ремесла',
    category7: 'Комплексні тури',
    category7First: 'Комплексні',
    category7Second: 'тури',
    category8: 'Місцеві цікавинки',
    category8First: 'Місцеві',
    category8Second: 'цікавинки',
    category9: 'Особливі пропозиції',
    category9First: 'Особливі',
    category9Second: 'пропозиції',
    readMore: 'Читати більше...',
    mission:
      'Місія «ЕКО-ЦЕНТРУ СТІНА» слугувати майданчиком співпраці різномантіних суб’єктів, що зацікавлені у практичній реалізації ідей сталого розвитку, обміні та збереженні сільської культури, а також реалізації сільських ініціатив.',
    about:
      'Стіна, це загадкове село на півдні Вінниччини, що ховається по-між лабіринтів вуличок і балок вздовж звивистого каньйону річки Русава. Тут час наче зупинився, а місцеві традиції оберігаються як найцінніше. Ми запрошуємо вас відчути неповторну атмосферу та щедрість стінянського краю',
  },
  en: {
    copyright: 'All rights reserved',
    heroText:
      'In the distant past, back in the Stone Age, there was a settlement of the Trypillia culture, now there are mounds and sleep-grass flourishes here, and the nearby village is called Stina.',
    embrText:
      'My Stina, you are my dear mother, so always be cheerful and meet all the guests. Wherever we are in the world, it draws our hearts, because we want to meet you, our native land',
    navLink1: 'ECO-CENTER',
    navLink2: 'GETTING HERE',
    navLink3: 'DISCOVER STINA',
    mapCategory1: 'Food',
    mapCategory2: 'Accommodation',
    mapCategory3: 'Shops',
    mapCategory4: 'Attractions',
    mapCategory5: 'Workshops',
    mapCategory6: 'Active recreation',
    mapCategory7: 'Establishments',
    mapCategory8: 'Local product',
    mapCategory9: 'Eco-Center',
    mapCategory10: 'Water source',
    category1: 'Food, Gastronomy',
    category1First: 'Food',
    category1Second: 'Gastronomy',
    category2: 'Accommodation, Placements',
    category2First: 'Accommodation',
    category2Second: 'Placements',
    category3: 'Local product',
    category3First: 'Local',
    category3Second: 'product',
    category4: 'Volunteering, Learning',
    category4First: 'Volunteering',
    category4Second: 'Learning',
    category5: 'Active recreation',
    category5First: 'Active',
    category5Second: 'recreation',
    category6: 'Workshops, Crafts',
    category6First: 'Workshops',
    category6Second: 'Crafts',
    category7: 'Complex tours',
    category7First: 'Complex',
    category7Second: 'tours',
    category8: 'Local attractions',
    category8First: 'Local',
    category8Second: 'attractions',
    category9: 'Special offers',
    category9First: 'Special',
    category9Second: 'offers',
    readMore: 'Read more ...',
    mission:
      'The mission of "ECO-CENTER STINA" to serve as a platform for cooperation of various actors interested in the practical implementation of ideas of sustainable development, exchange and preservation of rural culture, as well as the implementation of rural initiatives.',
    about:
      'Stina is a mysterious village in the South of Vinnytsia region that lurks among labyrinths of streets and gaps along the windy canyon of Rusava river. It seems the time has stopped here and local traditions are preserved as the most precious things. We invite you to experience the remarkable atmosphere and hospitality of the Stina countryside',
  },
};
