import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import arrowImage from 'assets/arrow.png';
import {
	Body1Bold,
} from 'components/Typography';
import Box from '@material-ui/core/Box';
import {
  ArrowImageLeft,
  ArrowImageRight,
	StyledSliderButton,
} from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

function ButtonsSliderMobile({outerSlider, buttons, startSlide, setCurrSlide}) {
  const swiperRef = useRef(null);

	useEffect(() => {
		if(!startSlide) {
			return
		}
		swiperRef.current.swiper.slideTo(startSlide);
	}, [])

	useEffect(() => {
		if (!swiperRef || !setCurrSlide) {
      return;
    }
    swiperRef.current.swiper.on('slideChange', (e) => {
			setCurrSlide(e.realIndex+1);
    });
	}, [swiperRef])

  return (
    <Box display="flex" alignItems="center" marginTop="20px">
			<ArrowImageLeft
				src={arrowImage}
				onClick={() => {
					swiperRef.current.swiper.slidePrev()
					if(outerSlider) {
						outerSlider.swiper.slidePrev()
					}
				}}
			/>
			<Swiper loop slidesPerView={1} ref={swiperRef} allowTouchMove={false}>
				{buttons.map(button => (
					<SwiperSlide key={button}>
						<StyledSliderButton>
							<Body1Bold>{button}</Body1Bold>
						</StyledSliderButton>
					</SwiperSlide>
					))
				}
			</Swiper>
			<ArrowImageRight
				src={arrowImage}
				onClick={() => {
					swiperRef.current.swiper.slideNext()
					if(outerSlider) {
						outerSlider.swiper.slideNext()
					}
				}}
			/>
    </Box>
  );
}

export default ButtonsSliderMobile;
