import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Feedbacker from 'components/Feedbacker';
import { storage } from '../../firebase';

function UploadFile({ setUrl, prevUrl, showProgress = true }) {
  const [upload, setUpload] = useState(null);
  const [fileError, setError] = useState(null);
  const [progresspercent, setProgresspercent] = useState(0);
  const inputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setUpload(file);
    const storageRef = ref(storage, `files/${file.name}_${uuidv4()}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        );
        setProgresspercent(progress);
      },
      (error) => {
        setError(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
        });
      },
    );
  };

  return (
    <>
      <Box marginTop="10px" display="flex" width="100%" alignItems="flex-end">
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={handleFileChange}
          ref={inputRef}
        />
        <Button
          onClick={() => inputRef.current.click()}
          color="primary"
          variant="contained"
        >
          Select file
        </Button>
        {upload && showProgress && (
          <Box width="70%" marginLeft="10px">
            <LinearProgress variant="determinate" value={progresspercent} />
          </Box>
        )}
      </Box>
      <Feedbacker
        open={Boolean(fileError)}
        severity="error"
        autoHideDuration={10000}
        feedbackMessage={fileError?.message}
        clearFeedback={() => setError(null)}
      />
    </>
  );
}

export default UploadFile;
