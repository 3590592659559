import styled from 'styled-components';
import Link from '@material-ui/core/Link';

export const FooterContainer = styled.div`
  ${({ theme }) => `
    background-color: black;
    display: flex;
    padding: 40px 60px;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: 60px 23px 5px 23px;
    }
  `}
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogosContainer = styled.div`
  ${({ theme }) => `
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      width: 80%;
      margin-bottom: 50px;
    }
  `}
`;

export const StyledLink = styled(Link)`
  text-decoration: none !important;
  color: white !important;
  text-transform: uppercase;
  padding-bottom: 20px;
`;

export const StyledContact = styled.div`
  color: white;
  // text-transform: uppercase;
  padding-bottom: 20px;
`;

export const StyledLogo1 = styled.img`
  width: 100%;
  height: auto;
  padding-bottom: 30px;
`;

export const StyledLogo2 = styled.img`
  width: 90%;
  height: auto;
  padding-bottom: 30px;
`;

export const StyledLogo3 = styled.img`
  width: 40%;
  height: auto;
`;

export const LeftContainer = styled.div`
  ${({ theme }) => `
    width: 33%;
    display: flex;
    justify-content: flex-start;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      margin-bottom: 45px;
    }
  `}
`;

export const CenterContainer = styled.div`
  ${({ theme }) => `
    width: 33%;
    display: flex;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 35px;
    }
  `}
`;

export const RightContainer = styled.div`
  ${({ theme }) => `
    width: 33%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    ${theme.breakpoints.down('sm')} {
        width: 100%;
        align-items: center;
      }
  `}
`;
