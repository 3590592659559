import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import {
  StyledTabLink,
  StyledTabButton,
  TabsContainer,
} from '../Transport/style';

function Tabs({ url, path }) {
  const tagsMatch = useRouteMatch(`${path}/Hashtags`);
  const footerContactMatch = useRouteMatch(`${path}/email&phNumbers`);
  const ecoCenterMatch = useRouteMatch(`${path}/eco-center`);

  return (
    <TabsContainer>
      <StyledTabLink to={`${url}/Hashtags`}>
        <StyledTabButton active={tagsMatch}>#Hashtags</StyledTabButton>
      </StyledTabLink>
      <StyledTabLink to={`${url}/email&phNumbers`}>
        <StyledTabButton active={footerContactMatch}>
          Emails and phone numbers
        </StyledTabButton>
      </StyledTabLink>
      <StyledTabLink to={`${url}/eco-center`}>
        <StyledTabButton active={ecoCenterMatch}>Eco-center</StyledTabButton>
      </StyledTabLink>
    </TabsContainer>
  );
}

export default Tabs;
