import styled from 'styled-components';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CloseIcon from '@material-ui/icons/Close';
import { Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';

export const MainPageContainer = styled.div`
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

export const HeroTextContent = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 100px;
    width: 32%;
    height: 100%;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;

    ${theme.breakpoints.down("sm")} {
      width: 100%;
      height: 45%;
    }
  `}
`;

export const MiddleTextContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45%;
    padding: 30px;
    background-color: rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
`;

export const HeroBottomMenu = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
`;

export const SocialButtonsContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const CategoriesSliderContainer = styled.div`
  ${({ theme }) => `
    padding: 20px;
    box-shadow: 0 15px 15px black;

    ${theme.breakpoints.down("sm")} {
      box-shadow: 0 5px 5px grey;
    }
  `}
`;

export const CategoryImage = styled.img`
  ${({ theme }) => `
    width: 80%;
    height: auto;
    border-radius: 50%;

    ${theme.breakpoints.down("sm")} {
      width: 80%;
    }
  `}
`;

export const ArrowImageLeft = styled.img`
  ${({ theme }) => `
    width: 90px;
    height: 120px;
    cursor: pointer;
    opacity: 0.6;

    ${theme.breakpoints.down("sm")} {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const ArrowImageRight = styled.img`
  ${({ theme }) => `
    width: 90px;
    height: 120px;
    transform: rotate(180deg);
    cursor: pointer;
    opacity: 0.6;

    ${theme.breakpoints.down("sm")} {
      width: 24px;
      height: 24px;
    }
  `}
`;

export const StyledSwiperSlide = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`;

export const LeftSideTextContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 30%;
  padding: 30px;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
`;

export const MapContainer = styled.div`
  ${({ theme }) => `
    position: relative;
    display: flex;
    height: 90vh;
    padding-left: 10px;
    padding-right: 10px;

    ${theme.breakpoints.down("sm")} {
      padding-left: 0px;
      padding-right: 0px;
    }
  `}
`;

export const MapCategoriesContainer = styled.div`
  position: absolute;
  top: 30px;
  bottom: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 350px;
  box-sizing: border-box;
  padding-right: 40px;
  padding-left: 10px;
  background-color: transparent;
  z-index: 1000;
`;

export const StyledMap = styled.div`
  ${({ theme }) => `
    position: relative;
    width: 100%;
    padding: 30px;
    padding-bottom: 40px;

    ${theme.breakpoints.down("sm")} {
      padding: 0px;
      padding-bottom: 20px;
    }
  `}
`;

export const MapCategoryItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px 0 50px;
  margin: 5px;
  background: white;
  border-radius: 50px;
  width: fit-content;
  height: 40px;
`;

export const StyledLocationIcon = styled(LocationOnIcon)`
  color: ${(props) => (props.$color ? props.$color : 'red')};
  font-size: 30px !important;
`;

export const SwiperContainer = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
`;

export const SliderPrev = styled.div`
  ${({ theme }) => `
    display: flex;
    padding-top: 4%;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 10;

    ${theme.breakpoints.down("sm")} {
      background-color: transparent;
      align-items: center;
    }
  `}
`;

export const SliderNext = styled.div`
${({ theme }) => `
  display: flex;
  padding-top: 4%;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;

  ${theme.breakpoints.down("sm")} {
      background-color: transparent;
      align-items: center;
    }
  `}
`;

export const CategoryNameTop = styled.div`
  position: absolute;
  top: 0;
  left: 10%;
  height: ${(props) => (props.isHover ? '50%' : '0%')};
  width: 80%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  transition: height 0.5s;
`;

export const CategoryNameBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 10%;
  height: ${(props) => (props.isHover ? '50%' : '0%')};
  width: 80%;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  transition: height 0.5s;
`;

export const CategoryLine = styled.div`
  position: absolute;
  bottom: 50%;
  left: 10%;
  height: 1px;
  width: 80%;
  background-color: ${(props) => (props.isHover ? 'grey' : 'transparent')};
  transition: ${(props) => (props.isHover ? 'all .1s .4s' : 'all .1s')};
`;

export const MapBoxContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledMapPopup = styled(Popup)`
  width: fit-content;

  .leaflet-popup-content {
    margin: 0;
  }
  .leaflet-popup-content-wrapper {
    padding: 0;
  }
`;

export const AboutContainer = styled.div`
  ${({ theme }) => `
    padding: 50px 20%;
    text-align: center;

    ${theme.breakpoints.down("sm")} {
      padding: 50px 20px;
    }
  `}
`;

export const SliderButtonsContainer = styled.div`
  ${({ theme }) => `
    position: absolute;
    display: flex;
    padding: 30px;
    top: 0;
    right: 0;
    z-index: 100;

    ${theme.breakpoints.down("sm")} {
      top: 85%;
      right: 50%;
      transform: translateX(50%);
    }

  `}
`;

export const SwiperPaginationButton = styled.div`
  ${({ theme, active }) => `
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin-right: 5px;
    cursor: pointer;
    background-color: rgba(255, 255, 255);
    opacity: ${active ? 1 : 0.4};

    ${theme.breakpoints.down("sm")} {
      height: 20px;
      width: 20px;
    }
  `}
`;

export const EcoCenterContainer = styled.div`
  position: relative;
  margin-bottom: 30px;
`;

export const EcoCenterText = styled.div`
  position: absolute;
  left: 0;
  top: 25%;
  width: ${(props) => (props.visible ? '33vw' : '0')};
  height: 50%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  transition: width 1s ease;
`;

export const EcoCenterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 33vw;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
`;

export const CategoryNameMain = styled.div`
  text-align: center;
  padding: 15px 20px;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const SlideGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`

export const StyledMobileSlide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`

export const MobileMapButton = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 40px;
  right: 50%;
  border-radius: 50%;
  transform: translateX(50%);
  background: white;
  z-index: 1000;
`

export const MobileIcon = styled.img`
  width: 28px;
  height: auto;
`;

export const MobileMapMenuContainer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  height: 60%;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px 20px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  z-index: 1000;
`

export const StyledCloseIcon = styled(CloseIcon)`
  position: absolute;
  top: 10px;
  right: 20px;
`
