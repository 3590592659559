import React from 'react';
import Button from '@material-ui/core/Button';
import * as validation from 'helpers/validationHelper';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import CustomTextField from 'components/CustomTextField';
import { Box } from '@material-ui/core';
import { ContactsContainer, FormContainer } from './style';

const validationSchema = yup.object().shape({});

function FooterContactsForm({
  addContact,
  contact,
  editContact,
  deleteContact,
}) {
  const handleFormSubmit = async (values, props) => {
    if (contact) {
      await editContact(values, contact.id);
      return;
    }

    await addContact(values);
    props.resetForm();
  };

  return (
    <Box>
      <ContactsContainer>
        <Formik
          validateOnChange
          initialValues={{
            name: contact ? contact.name : '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          <Form>
            <FormContainer>
              <Box width="500px">
                <CustomTextField label="contact" name="name" type="text" />
              </Box>

              <Box marginLeft="20px" width="200px" display="flex">
                <Button color="primary" type="submit" variant="outlined">
                  Save
                </Button>
                {contact && (
                  <Box marginLeft="10px">
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        deleteContact(contact.id, handleFormSubmit)
                      }
                    >
                      Delete
                    </Button>
                  </Box>
                )}
              </Box>
            </FormContainer>
          </Form>
        </Formik>
      </ContactsContainer>
    </Box>
  );
}

export default FooterContactsForm;
