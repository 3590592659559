import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  useParams,
  useLocation,
} from 'react-router-dom';
import useTranslation from 'hooks/useTranslation';
import { AppPath } from 'App';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { HeadingH6 } from 'components/Typography';
import Feedbacker from 'components/Feedbacker';
import {
  useArticlesDispatch,
  useArticlesState,
  addArticle,
  editArticle,
  deleteArticle,
  addDraft,
  getDraft,
  deleteDraft,
  removeSuccessMessage,
  editDraft,
} from 'components/useArticles';
import { removeImageFromStore } from 'firebase';
import { useCategories } from 'pages/MainPage/categories';
import { RoundIconButton } from 'components/Buttons';
import { populateCategories } from 'helpers/populateArticles';
import { populateCategoriesDrafts } from 'helpers/populateDrafts';
import { getArticleHeaderText } from 'helpers/textEditorHelper';
import Tabs from './Tabs';
import CreateArticle from './CreateArticle';
import { ArticlesContainer } from '../style';
import {
  CategoriesContainer,
  CategoryContainer,
  CategoryHeader,
  ArticlesArea,
  StyledDownIcon,
  ArticleThumbContainer,
  StyledImage,
  ArticleItemContainer,
} from './style';

function Articles() {
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentArticle, setCurrentArticle] = useState(null);
  const [fileError, setFileError] = useState(undefined);
  const categories = useCategories();
  const articleDispatch = useArticlesDispatch();
  const { articles, drafts, successMessage } = useArticlesState();
  const history = useHistory();
  const t = useTranslation();
  const { path, url, isExact } = useRouteMatch();
  const route = useRouteMatch();
  const params = useParams();
  const location = useLocation();
  const dispatch = useArticlesDispatch();

  useEffect(() => {
    getDraft(dispatch);
  }, [dispatch]);

  const handleAddArticle = async (data) => {
    await addArticle(articleDispatch, data);
  };
  const handleAddDraft = async (data) => {
    await addDraft(articleDispatch, data);
  };

  const handleOpenCategory = (category) => {
    if (currentCategory === category) {
      setCurrentCategory(null);
      return;
    }
    setCurrentCategory(category);
  };

  const handleDeleteArticle = async (article, categoryId) => {
    if (!article || !Array.isArray(article.categories)) {
      console.error(
        'Article or categories are undefined or not an array',
        article,
      );
      return;
    }

    if (article.categories.length > 1) {
      const updatedArticle = {
        ...article,
        categories: article.categories.filter((cat) => cat !== categoryId),
      };
      await editArticle(articleDispatch, updatedArticle, article.id);
      return;
    }
    await deleteArticle(articleDispatch, article.id);
  };

  const handleDeleteDraft = async (draft, categoryId) => {
    if (!draft || !Array.isArray(draft.categories)) {
      console.error('Draft or categories are undefined or not an array', draft);
      return;
    }

    if (draft.categories.length > 1) {
      const updatedDraft = {
        ...draft,
        categories: draft.categories.filter((cat) => cat !== categoryId),
      };
      await editDraft(articleDispatch, updatedDraft, draft.id);
      return;
    }
    await deleteDraft(articleDispatch, draft.id);
  };

  const handleEditArticle = async (article, articleId) => {
    const updatedArticle = {
      ...article,
      id: articleId,
    };
    await editArticle(articleDispatch, updatedArticle, articleId);
  };

  const handleEditDraft = async (draft, draftId) => {
    const updatedDraft = {
      ...draft,
      id: draftId,
    };
    await editDraft(articleDispatch, updatedDraft, draftId);
  };

  if (isExact) {
    return <Redirect to={`${url}/create`} />;
  }

  const articlPagesSwitch = (
    <Switch>
      <Route path={`${path}/create`}>
        <CreateArticle
          categories={categories}
          onAddArticle={handleAddArticle}
          onAddDraft={handleAddDraft}
          onRemoveDraft={handleDeleteDraft}
          onRemoveArticle={handleDeleteArticle}
          article={null}
          draft={null}
        />
      </Route>
      <Route path={`${path}/edit/:id`}>
        <CreateArticle
          categories={categories}
          onAddDraft={handleAddDraft}
          onEditArticle={handleEditArticle}
          onRemoveDraft={handleDeleteDraft}
          onRemoveArticle={handleDeleteArticle}
        />
      </Route>
      <Route path={`${path}/editDraft/:id`}>
        <CreateArticle
          categories={categories}
          onAddArticle={handleAddArticle}
          onEditDraft={handleEditDraft}
          onRemoveDraft={handleDeleteDraft}
          onRemoveArticle={handleDeleteArticle}
        />
      </Route>
      <Route path={`${path}/list`}>
        <CategoriesContainer>
          {populateCategories(categories, articles).map((category) => (
            <CategoryContainer key={category.id}>
              <CategoryHeader onClick={() => handleOpenCategory(category.id)}>
                <HeadingH6>{category.name}</HeadingH6>
                <StyledDownIcon selected={category.id === currentCategory} />
              </CategoryHeader>
              <Collapse in={category.id === currentCategory}>
                <ArticlesArea>
                  {category.articles.map((article) => (
                    <ArticleThumbContainer key={article.id}>
                      <ArticleItemContainer>
                        <StyledImage src={article.thumb} />
                      </ArticleItemContainer>
                      <Box textAlign="center">
                        <HeadingH6>
                          {getArticleHeaderText(t(article.header))}
                        </HeadingH6>
                      </Box>
                      <Box marginTop="10px" display="flex">
                        <Box marginRight="5px">
                          <RoundIconButton
                            type="button"
                            onClick={() =>
                              handleDeleteArticle(article, category.id)
                            }
                          >
                            <DeleteIcon />
                          </RoundIconButton>
                        </Box>
                        <RoundIconButton
                          type="button"
                          onClick={() => {
                            setCurrentArticle(article);
                            history.push(
                              `${AppPath.GOSPODAR}/articles/edit/${article.id}`,
                            );
                          }}
                        >
                          <EditIcon />
                        </RoundIconButton>
                      </Box>
                    </ArticleThumbContainer>
                  ))}
                </ArticlesArea>
              </Collapse>
            </CategoryContainer>
          ))}
        </CategoriesContainer>
      </Route>
      <Route path={`${path}/drafts`}>
        <CategoriesContainer>
          {populateCategoriesDrafts(categories, drafts).map((category) => (
            <CategoryContainer key={category.id}>
              <CategoryHeader onClick={() => handleOpenCategory(category.id)}>
                <HeadingH6>{category.name}</HeadingH6>
                <StyledDownIcon selected={category.id === currentCategory} />
              </CategoryHeader>
              <Collapse in={category.id === currentCategory}>
                <ArticlesArea>
                  {category.drafts.map((draft) => (
                    <ArticleThumbContainer key={draft.id}>
                      <ArticleItemContainer>
                        <StyledImage src={draft.thumb} />
                      </ArticleItemContainer>
                      <Box textAlign="center">
                        <HeadingH6>
                          {getArticleHeaderText(t(draft.header))}
                        </HeadingH6>
                      </Box>
                      <Box marginTop="10px" display="flex">
                        <Box marginRight="5px">
                          <RoundIconButton
                            type="button"
                            onClick={() =>
                              handleDeleteDraft(draft, category.id)
                            }
                          >
                            <DeleteIcon />
                          </RoundIconButton>
                        </Box>
                        <RoundIconButton
                          type="button"
                          onClick={() => {
                            setCurrentArticle(draft);
                            history.push(
                              `${AppPath.GOSPODAR}/articles/editDraft/${draft.id}`,
                            );
                          }}
                        >
                          <EditIcon />
                        </RoundIconButton>
                      </Box>
                    </ArticleThumbContainer>
                  ))}
                </ArticlesArea>
              </Collapse>
            </CategoryContainer>
          ))}
        </CategoriesContainer>
      </Route>
    </Switch>
  );

  return (
    <ArticlesContainer>
      <Tabs url={url} path={path} />
      {articlPagesSwitch}
      <Feedbacker
        open={Boolean(successMessage)}
        autoHideDuration={3000}
        feedbackMessage={successMessage}
        clearFeedback={() => removeSuccessMessage(articleDispatch)}
      />
    </ArticlesContainer>
  );
}

export default Articles;
