import {
  getCollectionsData,
  addDataToCollection,
  editDocumentInCollection,
  deleteDocument,
} from 'firebase';
import * as actionTypes from './actionTypes';

async function getArticles(dispatch) {
  try {
    const data = await getCollectionsData('articles');
    dispatch({ type: actionTypes.SUCCESS_ARTICLE, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_ARTICLE, error: error.message });
  }
}

async function addArticle(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('articles', body);
    dispatch({ type: actionTypes.ADD_ARTICLE, data: newDoc });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_ARTICLE, error: error.message });
  }
}

async function editArticle(dispatch, body, id) {
  try {
    await editDocumentInCollection('articles', id, body);
    dispatch({ type: actionTypes.EDIT_ARTICLE, data: body });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_ARTICLE, error: error.message });
  }
}

async function deleteArticle(dispatch, id) {
  try {
    await deleteDocument('articles', id);
    dispatch({ type: actionTypes.DELETE_ARTICLE, data: id });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_ARTICLE, error: error.message });
  }
}

async function getDraft(dispatch) {
  try {
    const data = await getCollectionsData('drafts');
    dispatch({ type: actionTypes.SUCCESS_DRAFT, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_DRAFT, error: error.message });
  }
}

async function addDraft(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('drafts', body);
    dispatch({ type: actionTypes.ADD_DRAFT, data: newDoc });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_DRAFT, error: error.message });
  }
}

async function editDraft(dispatch, body, id) {
  try {
    await editDocumentInCollection('drafts', id, body);
    dispatch({ type: actionTypes.EDIT_DRAFT, data: body });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_DRAFT, error: error.message });
  }
}

async function deleteDraft(dispatch, id) {
  try {
    await deleteDocument('drafts', id);
    dispatch({ type: actionTypes.DELETE_DRAFT, data: id });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_DRAFT, error: error.message });
  }
}

function removeSuccessMessage(dispatch) {
  dispatch({ type: actionTypes.REMOVE_MESSAGE });
}

export {
  addArticle,
  getArticles,
  editArticle,
  deleteArticle,
  addDraft,
  getDraft,
  editDraft,
  deleteDraft,
  removeSuccessMessage,
};
