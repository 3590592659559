import React from 'react';
import Image1 from 'assets/gallery/1.png';
import Image2 from 'assets/gallery/2.png';
import Image3 from 'assets/gallery/3.png';
import Image4 from 'assets/gallery/4.png';
import Image5 from 'assets/gallery/5.png';
import Image6 from 'assets/gallery/6.png';
import Image7 from 'assets/gallery/7.png';
import Image8 from 'assets/gallery/8.png';


import {
  Grid,
	Image,
	GridItem1,
	GridItem2,
	GridItem3,
	GridItem4,
	GridItem5,
	GridItem6,
	GridItem7,
	GridItem8,
} from './style';

function Collage() {
  return (
    <Grid>
			<GridItem1>
				<Image src={Image1}/>
			</GridItem1>
			<GridItem2>
				<Image src={Image2}/>
			</GridItem2>
			<GridItem3>
				<Image src={Image3}/>
			</GridItem3>
			<GridItem4>
				<Image src={Image4}/>
			</GridItem4>
			<GridItem5>
				<Image src={Image5}/>
			</GridItem5>
			<GridItem6>
				<Image src={Image6}/>
			</GridItem6>
			<GridItem7>
				<Image src={Image7}/>
			</GridItem7>
			<GridItem8>
				<Image src={Image8}/>
			</GridItem8>
		</Grid>
  );
}

export default Collage;
