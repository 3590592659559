import {
  getCollectionsData,
  addDataToCollection,
  deleteDocument,
  editDocumentInCollection,
} from 'firebase';
import * as actionTypes from './actionTypes';

async function getMembers(dispatch) {
  try {
    const data = await getCollectionsData('team');
    dispatch({ type: actionTypes.SUCCESS, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function addMember(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('team', body);
    dispatch({ type: actionTypes.ADD_MEMBER, data: newDoc });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function deleteMember(dispatch, id) {
  try {
    await deleteDocument('team', id);
    dispatch({ type: actionTypes.DELETE_MEMBER, data: id });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

async function editMember(dispatch, body, id) {
  try {
    await editDocumentInCollection('team', id, body);
    await getMembers(dispatch);
  } catch (error) {
    dispatch({ type: actionTypes.ERROR, error: error.message });
  }
}

export { getMembers, addMember, deleteMember, editMember };
