import styled from 'styled-components';

export const ArrowImageLeft = styled.img`
	cursor: pointer;
	opacity: 0.6;
	width: 24px;
	height: 24px;
`;

export const ArrowImageRight = styled.img`
	transform: rotate(180deg);
	cursor: pointer;
	opacity: 0.6;
	width: 24px;
	height: 24px;
`;

export const StyledSliderButton = styled.div`
	margin-right: 20px;
	cursor: pointer;
	width: 100%;
	color: black;
	text-align: center;
`;
