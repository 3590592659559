import React from 'react';
import { Body1 } from 'components/Typography';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArticlesSlider from './ArticlesSlider';
import { CategoryContainer, CategoryImage } from './style';

function Category({ category }) {
  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <>
      <CategoryContainer>
        <Box width={largerSm ? '65%' : '100%'}>
          <CategoryImage src={category.imageBig} />
        </Box>
        <Box
          width={largerSm ? '35%' : '100%'}
          padding={largerSm ? '10px 20px' : '0'}
          textAlign={largerSm ? 'start' : 'center'}
        >
          <Box padding={largerSm ? '0' : '20px'}>
            <Body1>{category.text}</Body1>
          </Box>
        </Box>
      </CategoryContainer>
      <ArticlesSlider
        articles={category.articles}
        currentCategoryTextId={category.textId}
      />
    </>
  );
}

export default Category;
