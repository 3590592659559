import React from 'react';
import { Formik, Form } from 'formik';
import CustomTextField from 'components/CustomTextField';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { HeadingH6 } from 'components/Typography';
import * as validation from 'helpers/validationHelper';
import * as yup from 'yup';
import UploadFile from '../UploadFile';

import { FormContainer, MemberImage, ImageContainer } from './style';

const validationSchema = yup.object().shape({});

function AddTeamMemberForm({ addMember, member, editMember, deleteMember }) {
  const handleFormSubmit = async (values, props) => {
    if (member) {
      await editMember(values, member.id);
      return;
    }

    await addMember(values);
    props.resetForm();
  };

  return (
    <Formik
      validateOnChange
      initialValues={{
        name: {
          uk: member ? member.name.uk : '',
          en: member ? member.name.en : '',
        },
        position: {
          uk: member ? member.position.uk : '',
          en: member ? member.position.en : '',
        },
        imageUrl: member ? member.imageUrl : '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <FormContainer>
            <Box marginBottom="10px" display="flex" alignItems="center">
              <ImageContainer>
                <MemberImage imageUrl={values.imageUrl} />
                <Box width="125px">
                  <UploadFile
                    setUrl={(option) => setFieldValue('imageUrl', option)}
                    prevUrl={values.imageUrl}
                    showProgress={false}
                  />
                </Box>
              </ImageContainer>
              <Box marginRight="5px" width="300px" marginBottom="50px">
                <HeadingH6>UA</HeadingH6>
                <Box marginBottom="10px" marginTop="10px">
                  <CustomTextField label="Name" name="name.uk" type="text" />
                </Box>
                <Box marginBottom="10px">
                  <CustomTextField
                    label="Position"
                    name="position.uk"
                    type="text"
                  />
                </Box>
              </Box>
              <Box width="300px" marginBottom="50px">
                <HeadingH6>EN</HeadingH6>
                <Box marginBottom="10px" marginTop="10px">
                  <CustomTextField label="Name" name="name.en" type="text" />
                </Box>
                <Box marginBottom="10px">
                  <CustomTextField
                    label="Position"
                    name="position.en"
                    type="text"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              marginLeft="20px"
              marginBottom="50px"
              width="200px"
              display="flex"
            >
              <Button color="primary" type="submit" variant="outlined">
                Save
              </Button>
              {member && (
                <Box marginLeft="10px">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => deleteMember(member.id)}
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          </FormContainer>
        </Form>
      )}
    </Formik>
  );
}

export default AddTeamMemberForm;
