import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import YouTubeModal from 'components/YouTubeModal';
import { getVideoPreviewImg } from 'helpers/videoHelpers';
import arrowImage from 'assets/arrow.png';
//import { useVideosState } from 'components/useVideos';
//import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  VideoImage,
  StyledSwiperVideoSlide,
  SliderPrev,
  SliderNext,
  SwiperContainer,
  ArrowImageLeft,
  ArrowImageRight,
  StyledYouTubeIcon,
} from './style';

import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

function Slider({ videos }) {
  const swiperRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [currentModalVideo, setCurrentModalVideo] = useState(null);

  const slidesPerView = 3;

  const [isYouTubeModalOpen, setYouTubeModalOpen] = useState(false);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const toggleYuuTubeModal = () => {
    setYouTubeModalOpen(!isYouTubeModalOpen);
  };

  useEffect(() => {
    if (!swiperRef) {
      return;
    }

    swiperRef.current.swiper.slideTo(slidesPerView);
  }, [swiperRef]);

  return (
    <>
      <SwiperContainer>
        <SliderPrev>
          <ArrowImageLeft
            src={arrowImage}
            onClick={() => swiperRef.current.swiper.slidePrev()}
          />
        </SliderPrev>
        <Swiper loop slidesPerView={largerSm ? slidesPerView : 1} ref={swiperRef}>
            {videos.map((video) => (
              <SwiperSlide key={video.id}>
                <StyledSwiperVideoSlide
                  onMouseEnter={() => setCurrentSlide(video)}
                  onMouseLeave={() => setCurrentSlide(null)}
                  onClick={() => {
                    setCurrentModalVideo(video);
                    toggleYuuTubeModal();
                  }}
                >
                  <VideoImage
                    src={getVideoPreviewImg(video.url)}
                    isHover={currentSlide?.id === video.id}
                  />
                  <StyledYouTubeIcon $isHover={currentSlide?.id === video.id} />
                </StyledSwiperVideoSlide>
              </SwiperSlide>
            ))}
        </Swiper>
        <SliderNext>
          <ArrowImageRight
            src={arrowImage}
            onClick={() => swiperRef.current.swiper.slideNext()}
          />
        </SliderNext>
      </SwiperContainer>
      <YouTubeModal
        open={isYouTubeModalOpen}
        onClose={toggleYuuTubeModal}
        currentVideo={currentModalVideo}
      />
    </>
  );
}

export default Slider;
