import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { useArticlesState } from 'components/useArticles';
import { useCategories } from 'pages/MainPage/categories';
import HeaderLight from 'components/HeaderLight';
import Article from 'components/Article';
import MobileHeader from 'components/Mobile/Header';
import { AppPath } from 'App';
import { selectArticlesForCategory } from 'helpers/populateArticles';
import useTranslation from 'hooks/useTranslation';
import { createSlug } from 'helpers/createSlug';
import { getArticleHeaderText } from 'helpers/textEditorHelper';
import useAuth from 'components/useAuth';
import { articlesPage } from 'strings/articles';
import { Body1 } from 'components/Typography';
import { RoundIconButton } from 'components/Buttons';
import ScrollToTop from 'components/ScrollToTop';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import arrowImage from 'assets/arrow.png';
import {
  PageContainer,
  NextArticleArrow,
  PrevArticleArrow,
  Bullet,
  BackArrow,
  ArrowsContainer,
  ArrowImageLeft,
  ArrowImageRight,
  ArticleContainer,
  Image,
} from './style';

function ArticlePage() {
  const params = useParams();
  const history = useHistory();
  const { articles } = useArticlesState();
  const categories = useCategories();

  const { currentUser } = useAuth();

  const [prevArticle, setPrevArticle] = useState(null);
  const [nextArticle, setNextArticle] = useState(null);
  const [pageTitle, setTitle] = useState('Статті про Стіну');
  const [pageDescription, setDescription] = useState('');

  const t = useTranslation();
  const strings = t(articlesPage);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const categoryArticles = selectArticlesForCategory(
    categories.find((el) => el.textId === params.categoryId).id,
    articles,
  );
  const articleIndex = categoryArticles.findIndex(
    (el) => el.slug === params.slug,
  );

  const calculateNextArticle = () => {
    const nextIndex =
      articleIndex + 1 > categoryArticles.length - 1 ? 0 : articleIndex + 1;
    return categoryArticles[nextIndex];
  };

  const calculatePrevArticle = () => {
    const prevIndex =
      articleIndex - 1 < 0 ? categoryArticles.length - 1 : articleIndex - 1;
    return categoryArticles[prevIndex];
  };

  useEffect(() => {
    setNextArticle(calculateNextArticle());
    setPrevArticle(calculatePrevArticle());
  }, [params, articles]);

  useEffect(() => {
    if (!categoryArticles[articleIndex]) {
      return;
    }
    setTitle(getArticleHeaderText(categoryArticles[articleIndex].header.uk));
    setDescription(categoryArticles[articleIndex].description.uk);
  }, [articleIndex]);

  const goToNextArticle = () => {
    history.push(
      `${AppPath.CATEGORY}/${params.categoryId}/articles/${nextArticle.slug}`,
    );
  };

  const goToPrevArticle = () => {
    history.push(
      `${AppPath.CATEGORY}/${params.categoryId}/articles/${prevArticle.slug}`,
    );
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <ScrollToTop />
      {largerSm ? <HeaderLight /> : <MobileHeader />}
      <PageContainer>
        {largerSm && (
          <Box
            display="flex"
            justifyContent="space-between"
            marginBottom="10px"
          >
            <Box display="flex" alignItems="center">
              <BackArrow
                fontSize="large"
                onClick={() =>
                  history.push(`${AppPath.CATEGORY}/${params.categoryId}`)
                }
              />
              <Box marginLeft="5px">
                <Body1>{strings.back}</Body1>
              </Box>
            </Box>
            {currentUser && (
              <RoundIconButton
                type="button"
                onClick={() => {
                  history.push(
                    `${AppPath.GOSPODAR}/articles/edit/${categoryArticles[articleIndex].id}`,
                  );
                }}
              >
                <EditIcon />
              </RoundIconButton>
            )}
          </Box>
        )}
        {largerSm && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginBottom="10px"
          >
            <PrevArticleArrow fontSize="large" onClick={goToPrevArticle} />
            <Box display="flex">
              {categoryArticles.map((el, index) => (
                <Bullet key={el.id} active={index === articleIndex} />
              ))}
            </Box>
            <NextArticleArrow fontSize="large" onClick={goToNextArticle} />
          </Box>
        )}
        <Article articleData={categoryArticles[articleIndex]} />
        {!largerSm && (
          <ArrowsContainer>
            <ArrowImageLeft src={arrowImage} onClick={goToPrevArticle} />
            <ArrowImageRight src={arrowImage} onClick={goToNextArticle} />
          </ArrowsContainer>
        )}
        {!largerSm &&
          prevArticle &&
          nextArticle &&
          categoryArticles.length > 1 && (
            <Box display="flex" justifyContent="center">
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginTop="30px"
                width="50%"
              >
                <ArticleContainer onClick={goToPrevArticle}>
                  <Image src={prevArticle.thumb} />
                </ArticleContainer>
                <Box width="70%" textAlign="center" paddingTop="10px">
                  <Body1>{getArticleHeaderText(t(prevArticle.header))}</Body1>
                </Box>
              </Box>
              {categoryArticles.length > 3 && (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  marginTop="30px"
                  width="50%"
                >
                  <ArticleContainer onClick={goToNextArticle}>
                    <Image src={nextArticle.thumb} />
                  </ArticleContainer>
                  <Box width="70%" textAlign="center" paddingTop="10px">
                    <Body1>{getArticleHeaderText(t(nextArticle.header))}</Body1>
                  </Box>
                </Box>
              )}
            </Box>
          )}
      </PageContainer>
    </>
  );
}

export default ArticlePage;
