import {
  getCollectionsData,
  addDataToCollection,
  deleteDocument,
  editDocumentInCollection,
} from 'firebase';
import * as actionTypes from './actionTypes';

async function getHashtags(dispatch) {
  try {
    const data = await getCollectionsData('contacts-tag');
    dispatch({ type: actionTypes.SUCCESS_HASHTAG, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_HASHTAG, error: error.message });
  }
}

async function addHashtag(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('contacts-tag', body);
    dispatch({ type: actionTypes.ADD_HASHTAG, data: newDoc });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_HASHTAG, error: error.message });
  }
}

async function deleteHashtag(dispatch, id) {
  try {
    await deleteDocument('contacts-tag', id);
    dispatch({ type: actionTypes.DELETE_HASHTAG, data: id });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_HASHTAG, error: error.message });
  }
}

async function editHashtag(dispatch, body, id) {
  try {
    await editDocumentInCollection('contacts-tag', id, body);
    await getHashtags(dispatch);
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_HASHTAG, error: error.message });
  }
}

async function getFooterContacts(dispatch) {
  try {
    const data = await getCollectionsData('footer-contacts');
    dispatch({ type: actionTypes.SUCCESS_FOOTERCONTACT, data });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_FOOTERCONTACT, error: error.message });
  }
}

async function addFooterContact(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('footer-contacts', body);
    dispatch({ type: actionTypes.ADD_FOOTERCONTACT, data: newDoc });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_FOOTERCONTACT, error: error.message });
  }
}

async function deleteFooterContact(dispatch, id) {
  try {
    await deleteDocument('footer-contacts', id);
    dispatch({ type: actionTypes.DELETE_FOOTERCONTACT, data: id });
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_FOOTERCONTACT, error: error.message });
  }
}

async function editFooterContact(dispatch, body, id) {
  try {
    await editDocumentInCollection('footer-contacts', id, body);
    await getFooterContacts(dispatch);
  } catch (error) {
    dispatch({ type: actionTypes.ERROR_FOOTERCONTACT, error: error.message });
  }
}

async function getEcoCenterContacts(dispatch) {
  try {
    const data = await getCollectionsData('eco-center-contacts');
    dispatch({ type: actionTypes.SUCCESS_ECOCENTERCONTACT, data });
  } catch (error) {
    dispatch({
      type: actionTypes.ERROR_ECOCENTERCONTACT,
      error: error.message,
    });
  }
}

async function addEcoCenterContact(dispatch, body) {
  try {
    const newDoc = await addDataToCollection('eco-center-contacts', body);
    dispatch({ type: actionTypes.ADD_ECOCENTERCONTACT, data: newDoc });
  } catch (error) {
    dispatch({
      type: actionTypes.ERROR_ECOCENTERCONTACT,
      error: error.message,
    });
  }
}

async function deleteEcoCenterContact(dispatch, id) {
  try {
    await deleteDocument('eco-center-contacts', id);
    dispatch({ type: actionTypes.DELETE_ECOCENTERCONTACT, data: id });
  } catch (error) {
    dispatch({
      type: actionTypes.ERROR_ECOCENTERCONTACT,
      error: error.message,
    });
  }
}

async function editEcoCenterContact(dispatch, body, id) {
  try {
    await editDocumentInCollection('eco-center-contacts', id, body);
    await getEcoCenterContacts(dispatch);
  } catch (error) {
    dispatch({
      type: actionTypes.ERROR_ECOCENTERCONTACT,
      error: error.message,
    });
  }
}
export {
  getHashtags,
  addHashtag,
  deleteHashtag,
  editHashtag,
  getFooterContacts,
  addFooterContact,
  deleteFooterContact,
  editFooterContact,
  getEcoCenterContacts,
  addEcoCenterContact,
  deleteEcoCenterContact,
  editEcoCenterContact,
};
