import { useContext } from 'react';

import TeamProvider from './Provider';
import { TeamDispatchContext, TeamStateContext } from './Context';
import { getMembers, addMember, deleteMember, editMember } from './actions';

function useTeamState() {
  const context = useContext(TeamStateContext);
  if (context === undefined) {
    throw new Error('useTeamState must be used within a TeamProvider');
  }
  return context;
}

function useTeamDispatch() {
  const context = useContext(TeamDispatchContext);
  if (context === undefined) {
    throw new Error('useTeamDispatch must be used within a TeamProvider');
  }
  return context;
}

function useTeam() {
  return [useTeamState(), useTeamDispatch()];
}

export default useTeam;

export {
  TeamProvider,
  useTeamState,
  useTeamDispatch,
  useTeam,
  getMembers,
  addMember,
  deleteMember,
  editMember,
};
