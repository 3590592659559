import React, { useReducer } from 'react';
import ContactReducer, { initialState } from './reducer';
import { ContactStateContext, ContactDispatchContext } from './Context';

function ContactProvider({ children }) {
  const [state, dispatch] = useReducer(ContactReducer, initialState);

  return (
    <ContactStateContext.Provider value={state}>
      <ContactDispatchContext.Provider value={dispatch}>
        {children}
      </ContactDispatchContext.Provider>
    </ContactStateContext.Provider>
  );
}

export default ContactProvider;
