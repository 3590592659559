import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useHistory } from 'react-router-dom';
import { useCategories } from 'pages/MainPage/categories';
import HeaderLight from 'components/HeaderLight';
import MobileHeader from 'components/Mobile/Header';
import VideosSlider from 'components/VideosSlider';
import SocialLinks from 'components/SocialLinks';
import { useArticlesState } from 'components/useArticles';
import { HeadingH6Bold, Body1 } from 'components/Typography';
import ButtonsSliderMobile from 'components/Mobile/ButtonsSlider';
import ScrollToTop from 'components/ScrollToTop';
import {
  useScrollState,
  useScrollDispatch,
  setScrollToCategories,
} from 'components/useScroll';
import { populateCategoriesObjectForm } from 'helpers/populateArticles';
import { AppPath } from 'App';
import useTranslation from 'hooks/useTranslation';
import placesImageBig from 'assets/what/place.png';
import stripeImage from 'assets/stripe.png';
import stripeImageMobile from 'assets/mobile/stripe.png';
import { mainPage } from 'strings/mainPage';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Category from './Category';
import {
  PageContainer,
  AboutContainer,
  CategoryImage,
  StyledSliderButton,
  ButtonsContainer,
} from './style';

function WhatToDo() {
  const categories = useCategories();
  const params = useParams();
  const history = useHistory();
  const [scrollTop, setScrollTop] = useState(true);
  const [currentCategory, setCurrentCategory] = useState(
    categories.find((el) => el.textId === params.id),
  );

  const { scrollToCategory } = useScrollState();
  const scrollDispatch = useScrollDispatch();

  const stripeElement = useRef(null);

  const { articles } = useArticlesState();

  const t = useTranslation();
  const strings = t(mainPage);

  const theme = useTheme();
  const largerSm = useMediaQuery(theme.breakpoints.up('sm'));

  const populatedCategories = populateCategoriesObjectForm(
    categories,
    articles,
  );

  useEffect(() => {
    if (scrollToCategory) {
      stripeElement.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    setCurrentCategory(categories.find((el) => el.textId === params.id));
  }, [params.id]);

  return (
    <>
      <Helmet>
        <title>Відкрий Стіну</title>
        <meta name="description" content={currentCategory?.text} />
      </Helmet>
      <ScrollToTop scrollTop={scrollTop} />
      {largerSm ? <HeaderLight /> : <MobileHeader />}
      <PageContainer>
        <AboutContainer>
          <Box
            display="flex"
            flexDirection="column"
            width={largerSm ? '40%' : '100%'}
            padding={largerSm ? '20px' : '0'}
            justifyContent="space-around"
          >
            <Box
              textAlign={largerSm ? 'start' : 'center'}
              padding={largerSm ? '0' : '0 25px'}
            >
              <Body1>{strings.about}</Body1>
            </Box>
            <Box
              display="flex"
              height="80px"
              alignItems="center"
              justifyContent={largerSm ? 'flex-start' : 'center'}
              marginTop="40px"
            >
              <SocialLinks />
            </Box>
          </Box>
          <Box width={largerSm ? '60%' : '100%'}>
            <CategoryImage src={placesImageBig} />
          </Box>
        </AboutContainer>
        <Box ref={stripeElement} />
        <Box marginBottom="25px" marginTop={largerSm ? '25px' : '45px'}>
          <CategoryImage src={largerSm ? stripeImage : stripeImageMobile} />
        </Box>
        {largerSm ? (
          <ButtonsContainer>
            {categories.map((category) => (
              <StyledSliderButton
                key={category.id}
                active={category.id === currentCategory?.id}
                onClick={() => {
                  history.push(`${AppPath.CATEGORY}/${category.textId}`);
                  setScrollTop(false);
                  setScrollToCategories(scrollDispatch, false);
                }}
              >
                <HeadingH6Bold>{category.name}</HeadingH6Bold>
              </StyledSliderButton>
            ))}
          </ButtonsContainer>
        ) : (
          <ButtonsSliderMobile
            buttons={categories.map((category) => category.name)}
            startSlide={currentCategory?.id}
            setCurrSlide={(id) =>
              setCurrentCategory(categories.find((el) => el.id === id))
            }
          />
        )}
        <Category category={populatedCategories[currentCategory?.id]} />
      </PageContainer>
      <VideosSlider />
    </>
  );
}

export default WhatToDo;
